// See: https://www.sitepoint.com/gatsby-mdx-blog/

import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { useState, useEffect } from "react"

export default function Blog({ data, location }) {

  // Load more functionality,
  // see: https://www.erichowey.dev/writing/load-more-button-and-infinite-scroll-in-gatsby/

  // Array of all posts
  const allPosts = data.allMdx.nodes
  const CHUNK_LENGTH = 10;

  // State for the list
  const [list, setList] = useState([...allPosts.slice(0, CHUNK_LENGTH)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allPosts.length > CHUNK_LENGTH)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + CHUNK_LENGTH)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allPosts.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (

    <Layout location={location}>
      <h1>Reflektioner</h1>
      <p className="page-intro">Mina oregelbundet uppdaterade reflektioner
      över livet, processer, team, konst, filosofi, hållbarhet och annat. Ibland
      på engelska, ibland på svenska.</p>
      <div className = "list-blog-wrapper">
          {list.map(({ id, frontmatter, slug }) => (
              <article className = "list-blog-post"
                key={id}
              >
                <Link to={`/${slug}`}>
                  <header>
                    <h2>{frontmatter.title}</h2>
                  </header>
                  <p>
                    {frontmatter.intro}
                  </p>
                  <StaticImage
                     src="../images/arrow-right.png"
                     alt="Pil höger"
                     className = "arrow-right"
                     placeholder="blurred"
                     layout="constrained"
                   />
                </Link>
              </article>
            ))}
            {hasMore ? (
              <button className="button-load-more" onClick={handleLoadMore}>Visa fler reflektioner</button>
            ) : (
              ""
            )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "D MMMM YYYY", locale: "sv")
          intro
        }
        slug
      }
    }
  }
`;
